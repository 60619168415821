<template>
  <div>
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
            OLÁ, SEJA BEM-VINDO A NEGOCIAÇÃO DO CLIENTE
          </h2>
          <b-row>
            <b-col md="12">
              <SearchBar />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </section>
    <b-card-group>
      <b-card>
        <BuscasRecentes />
      </b-card>
      <b-card>
        <Favoritos />
      </b-card>
    </b-card-group>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardGroup,
} from 'bootstrap-vue'

import SearchBar from './components/SearchBar.vue'
import Favoritos from './components/Favoritos.vue'
import BuscasRecentes from './components/BuscasRecentes.vue'

// import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    SearchBar,
    BCardBody,
    BCardGroup,
    Favoritos,
    BuscasRecentes,
  },
  data() {
    return {
    }
  },
  async created() {
    await this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  methods: {
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
