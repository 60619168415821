<template>
  <div>
    <b-card-group deck>
      <b-card header="Minhas Buscas Recentes">
        <b-list-group>
          <b-list-group-item
            v-for="(item,index) in items"
            :key="index"
            class="cursor-pointer"
            @click="itemClick(item)"
          >
            {{ item }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <feather-icon
        icon="TrashIcon"
        size="18"
        class="mr-4 mt-2 cursor-pointer color-icon"
        @click="limparBuscas()"
      />
    </b-card-group>
  </div>
</template>

<script>
import { BCard, BCardGroup, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardGroup,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.getSearchHistory()
  },
  methods: {
    getSearchHistory() {
      const getHistory = JSON.parse(localStorage.getItem('buscasRecentes'))
      if (getHistory) { this.items = getHistory.sort((a, b) => a - b) }
    },
    itemClick(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item } })
    },
    async limparBuscas() {
      localStorage.removeItem('buscasRecentes')
      await this.getSearchHistory()
    },
  },
}
</script>
