<template>
  <div>
    <b-card-group deck>
      <b-card header="Meus Clientes Favoritos">
        <b-list-group>
          <b-list-group-item
            v-for="(item, index) in items"
            :key="index"
            class="cursor-pointer"
            @click="itemClick(item)"
          >
            {{ item.cliente_id }} - {{ item.nome }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { BCard, BCardGroup, BListGroup, BListGroupItem } from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BCard,
    BCardGroup,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.getFavoritos()
  },
  methods: {
    getFavoritos() {
      const body = {
        usuario_empresas_id: parseInt(localStorage.getItem('usuarioEmpresas')),
      }
      axios.post('api/v1/cliente_favorito/listar', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
        .then(res => {
          res.data.dados.map(item => {
            this.items.push(item)
          })
        })
    },
    itemClick(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.cliente_id } })
    },
  },
}
</script>
